import React, { Dispatch, SetStateAction } from 'react';
import * as s from './OrderStyled';
import { Link } from 'react-router-dom';
import { priceToString } from 'util/util';
import theme from 'components/styles/theme';
import { FaChevronDown } from 'react-icons/fa';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';

interface OrderProps {
  pg: number;
  setPg: Dispatch<SetStateAction<number>>;
  pgList: any[];
  payment: () => void;
  itemList: any[];
  goToProductDetail: (productId: number) => void;
  userInfo: any;
  ordererEmail: string;
  setOrdererEmail: Dispatch<SetStateAction<string>>;
  receiverNm: string;
  setReceiverNm: Dispatch<SetStateAction<string>>;
  receiverMobno: string;
  setReceiverMobno: Dispatch<SetStateAction<string>>;
  orderRequestList: string[];
  orderRequest: string;
  setOrderRequest: Dispatch<SetStateAction<string>>;
  orderRequestDetail: string;
  setOrderRequestDetail: Dispatch<SetStateAction<string>>;
  agreePayment: boolean;
  setAgreePayment: Dispatch<SetStateAction<boolean>>;
  orderProduct: () => void;
  deliveryZipcode: string;
  deliveryAddr: string;
  deliveryAddrDetail: string;
  setDeliveryAddrDetail: Dispatch<SetStateAction<string>>;
  setIsOpenPost: Dispatch<SetStateAction<boolean>>;
}

const OrderPresenter = (props: OrderProps) => {
  return (
    <s.Container>
      <s.ContentArea>
        <s.OrderTitle>주문결제</s.OrderTitle>
        <s.SectionWrapper>
          <s.ItemListArea>
            <s.SectionHeader border={`2px solid ${theme.color.primary}`}>
              상품목록
            </s.SectionHeader>
            <s.ProductItemWrapper>
              {props.itemList.map((product: any, index: number) => (
                <s.ProductItem>
                  <s.ProductItemThumbnail
                    src={product.thumbnail}
                    onClick={() => props.goToProductDetail(product.productId)}
                  />
                  <s.ProductTextList>
                    <s.ProductNm
                      onClick={() => props.goToProductDetail(product.productId)}
                    >
                      {product.productNm}
                    </s.ProductNm>
                    <s.ModelNm>
                      <s.BrandNm>{product.brandNm}</s.BrandNm>
                      {product.modelNm}
                    </s.ModelNm>
                    <s.OptionNm>
                      {product.optionNm} / 현재재고 : {product.stock}EA
                    </s.OptionNm>
                    <s.ItemCountArea>
                      주문수량 : {product.count}EA
                    </s.ItemCountArea>
                    <s.TotalPrice>
                      {priceToString(
                        (Number(product.priceDiscount) +
                          Number(product.optionPrice)) *
                          Number(product.count),
                      )}{' '}
                      원
                    </s.TotalPrice>
                  </s.ProductTextList>
                </s.ProductItem>
              ))}
            </s.ProductItemWrapper>

            <s.SectionHeader border={`2px solid ${theme.color.primary}`}>
              주문자 정보
            </s.SectionHeader>
            <s.OrderInfoTable>
              <s.OrderInfoTbody>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>
                    이름/휴대폰
                    <s.OrderInfoSpan color={'#ff0000'}>*</s.OrderInfoSpan>
                  </s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.OrderInfoInput
                      disabled
                      backgroundColor={theme.color.grey}
                      value={
                        props.userInfo.userNm + '/' + props.userInfo.userMobno
                      }
                    />
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>
                    이메일
                    <s.OrderInfoSpan color={'#ff0000'}>*</s.OrderInfoSpan>
                  </s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.OrderInfoInput
                      type="text"
                      placeholder="이메일을 입력하세요."
                      value={props.ordererEmail}
                      onChange={(e) => props.setOrdererEmail(e.target.value)}
                      maxLength={200}
                    />
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
              </s.OrderInfoTbody>
            </s.OrderInfoTable>

            <s.SectionHeader border={`2px solid ${theme.color.primary}`}>
              배송 정보
            </s.SectionHeader>
            <s.OrderInfoTable>
              <s.OrderInfoTbody>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>
                    배송지
                    <s.OrderInfoSpan color={'#ff0000'}>*</s.OrderInfoSpan>
                  </s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.OrderInfoHorizontalWrapper margin>
                      <s.OrderInfoInput
                        type="text"
                        placeholder="우편번호"
                        style={{
                          width: '10rem',
                          backgroundColor: theme.color.grey,
                        }}
                        value={props.deliveryZipcode}
                        disabled
                        maxLength={50}
                      />
                      <s.OpenAddrSearchButton
                        onClick={() => props.setIsOpenPost(true)}
                      >
                        주소검색
                      </s.OpenAddrSearchButton>
                    </s.OrderInfoHorizontalWrapper>
                    <s.OrderInfoInput
                      type="text"
                      margin
                      placeholder="배송지 주소"
                      style={{
                        backgroundColor: theme.color.grey,
                      }}
                      value={props.deliveryAddr}
                      disabled
                      maxLength={50}
                    />
                    <s.OrderInfoInput
                      type="text"
                      placeholder="상세주소를 입력하세요"
                      value={props.deliveryAddrDetail}
                      onChange={(e) =>
                        props.setDeliveryAddrDetail(e.target.value)
                      }
                      maxLength={50}
                    />
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>
                    수령인(이름/휴대폰)
                    <s.OrderInfoSpan color={'#ff0000'}>*</s.OrderInfoSpan>
                  </s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.OrderInfoInput
                      type="text"
                      placeholder="이름을 입력하세요"
                      margin
                      value={props.receiverNm}
                      onChange={(e) => props.setReceiverNm(e.target.value)}
                      maxLength={50}
                    />
                    <s.OrderInfoInput
                      type="text"
                      placeholder="휴대폰번호를 입력하세요"
                      value={props.receiverMobno}
                      onChange={(e) => props.setReceiverMobno(e.target.value)}
                      maxLength={13}
                    />
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>배송요청사항</s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.PopupOptionSelectChevron>
                      <FaChevronDown
                        size={'100%'}
                        color={theme.color.lightGrey}
                      />
                    </s.PopupOptionSelectChevron>
                    <s.OrderInfoSelect
                      value={props.orderRequest}
                      onChange={(e) => props.setOrderRequest(e.target.value)}
                      margin
                    >
                      <option value="" style={{ color: theme.color.lightGrey }}>
                        배송기사에게 요청할 메세지를 선택하세요.
                      </option>
                      {props.orderRequestList.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))}
                    </s.OrderInfoSelect>
                    {props.orderRequest === '기타' ? (
                      <s.OrderInfoInput
                        type="text"
                        placeholder="요청상세내용을 입력하세요."
                        value={props.orderRequestDetail}
                        onChange={(e) =>
                          props.setOrderRequestDetail(e.target.value)
                        }
                        maxLength={200}
                      />
                    ) : null}
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
              </s.OrderInfoTbody>
            </s.OrderInfoTable>

            <s.SectionHeader border={`2px solid ${theme.color.primary}`}>
              결제수단
            </s.SectionHeader>
            <s.OrderInfoTable>
              <s.OrderInfoTbody>
                <s.OrderInfoTr>
                  <s.OrderInfoTh>
                    결제수단
                    <s.OrderInfoSpan color={'#ff0000'}>*</s.OrderInfoSpan>
                  </s.OrderInfoTh>
                  <s.OrderInfoTd>
                    <s.PgListBox>
                      {props.pgList.map((pgInfo: any, index: number) => (
                        <s.PgButton
                          active={props.pg === index}
                          onClick={() => props.setPg(index)}
                        >
                          <s.PgIcon src={pgInfo.img} />
                          {pgInfo.name}
                        </s.PgButton>
                      ))}
                    </s.PgListBox>
                  </s.OrderInfoTd>
                </s.OrderInfoTr>
              </s.OrderInfoTbody>
            </s.OrderInfoTable>
          </s.ItemListArea>
          <s.SummaryArea>
            <s.SectionHeader border="0">결제 금액</s.SectionHeader>
            <s.SummaryTopBox>
              <s.SummaryTopTitle>전체 합계</s.SummaryTopTitle>
              <s.SummaryTopBoxRow>
                제품 수
                <s.SummaryTopBoxRowCount>
                  {priceToString(
                    props.itemList.reduce((a: any, b: any) => a + b.count, 0),
                  )}
                  EA
                </s.SummaryTopBoxRowCount>
              </s.SummaryTopBoxRow>
              <s.SummaryTopBoxRow>
                주문금액
                <s.SummaryTopBoxRowCount>
                  {priceToString(
                    props.itemList.reduce(
                      (a: any, b: any) =>
                        a + (b.priceDiscount + b.optionPrice) * b.count,
                      0,
                    ),
                  )}
                  원
                </s.SummaryTopBoxRowCount>
              </s.SummaryTopBoxRow>
              <s.SummaryTopBoxRow>
                배송비
                <s.SummaryTopBoxRowCount>
                  {priceToString(
                    props.itemList.reduce(
                      (a: any, b: any) => a + Number(b.deliveryPrice),
                      0,
                    ),
                  )}
                  원
                </s.SummaryTopBoxRowCount>
              </s.SummaryTopBoxRow>
              <s.SummaryTopBoxTotal>
                최종 결제금액
                <s.SummaryTopBoxTotalCount>
                  {priceToString(
                    Number(
                      props.itemList.reduce(
                        (a: any, b: any) =>
                          a + (b.priceDiscount + b.optionPrice) * b.count,
                        0,
                      ) +
                        Number(
                          props.itemList.reduce(
                            (a: any, b: any) => a + Number(b.deliveryPrice),
                            0,
                          ),
                        ),
                    ),
                  )}
                  원
                </s.SummaryTopBoxTotalCount>
              </s.SummaryTopBoxTotal>
            </s.SummaryTopBox>
            <s.AgreementBox>
              <s.AgreementIcon
                onClick={() => props.setAgreePayment(!props.agreePayment)}
              >
                {props.agreePayment ? (
                  <AiFillCheckCircle color={theme.color.green} size={'100%'} />
                ) : (
                  // eslint-disable-next-line react/jsx-no-undef
                  <AiOutlineCheckCircle
                    color={theme.color.lightGrey}
                    size={'100%'}
                  />
                )}
              </s.AgreementIcon>
              <s.AgreementStatement>
                약관 내용을 확인하였으며,
                <br />
                개인정보 이용 및 제공과 결제에 동의합니다.
              </s.AgreementStatement>
            </s.AgreementBox>
            {Number(
              props.itemList.reduce(
                (a: any, b: any) =>
                  a + (b.priceDiscount + b.optionPrice) * b.count,
                0,
              ) +
                Number(
                  props.itemList.reduce(
                    (a: any, b: any) => a + Number(b.deliveryPrice),
                    0,
                  ),
                ),
            ) > 0 ? (
              <s.OrderButton onClick={() => props.orderProduct()}>
                {priceToString(
                  Number(
                    props.itemList.reduce(
                      (a: any, b: any) =>
                        a + (b.priceDiscount + b.optionPrice) * b.count,
                      0,
                    ) +
                      Number(
                        props.itemList.reduce(
                          (a: any, b: any) => a + Number(b.deliveryPrice),
                          0,
                        ),
                      ),
                  ),
                )}{' '}
                원 구매하기
              </s.OrderButton>
            ) : null}
          </s.SummaryArea>
        </s.SectionWrapper>
      </s.ContentArea>
    </s.Container>
  );
};

export default OrderPresenter;
